<template>
  <div>
    <b-card title="Raw autosupport data">
      <json-viewer
        :value="asupRawJson"
        :expand-depth="1"
        copyable
        sort
      />
    </b-card>
  </div>
</template>

<script>

import JsonViewer from 'vue-json-viewer'

import { BCard } from 'bootstrap-vue'

import RawAsupSystemService from '@/service/rawAsupSystem.service'

export default {
  components: {
    BCard,
    JsonViewer,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      asupRawJson: {},
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      RawAsupSystemService.getAsync(this.asup.id, { disableTenantFilter: true })
        .then(result => {
          this.asupRawJson = JSON.parse(result.jdata)
        })
    },
  },
}
</script>
